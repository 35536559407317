import React from 'react';
import { Link } from 'react-router-dom';
import constructURL from '../../utils/constructURL';
import classnames from 'classnames';
import { ReactComponent as ViewTilesIcon } from '../../images/icons/card-view.svg';
import { ReactComponent as ViewTwoColumnsIcon } from '../../images/icons/list-view.svg';
import styles from './SearchResultsPage.module.scss';
import { VIEW_OPTIONS_TILES, VIEW_OPTIONS_COLUMNS } from './SRP.definitions';

const ViewOptions = ({ isTablet, viewOption }) => {
  if (isTablet) {
    return (
      <ul className={classnames(styles.viewOptions, 'col-2-xs col-7-m col-1-xl')}>
        <li>
          <Link
            aria-label="Tiles view"
            className={classnames(styles.viewOption, {
              [styles.viewOptionSelected]: viewOption === VIEW_OPTIONS_TILES,
            })}
            to={constructURL(`?view=${VIEW_OPTIONS_TILES}`)}
          >
            <ViewTilesIcon />
          </Link>
        </li>
        <li>
          <Link
            aria-label="Column view"
            className={classnames(styles.viewOption, {
              [styles.viewOptionSelected]: viewOption === VIEW_OPTIONS_COLUMNS,
            })}
            to={constructURL(`?view=${VIEW_OPTIONS_COLUMNS}`)}
          >
            <ViewTwoColumnsIcon />
          </Link>
        </li>
      </ul>
    );
  } else {
    return (
      <ul className={classnames(styles.viewOptions, 'col-1-xs col-7-m col-1-xl')}>
        {viewOption === VIEW_OPTIONS_TILES && (
          <li>
            <Link
              aria-label="Tiles view"
              className={classnames(styles.viewOption, styles.viewOptionSelected)}
              to={constructURL(`?view=${VIEW_OPTIONS_COLUMNS}`)}
            >
              <ViewTilesIcon />
            </Link>
          </li>
        )}
        {viewOption === VIEW_OPTIONS_COLUMNS && (
          <li>
            <Link
              aria-label="Tiles view"
              className={classnames(styles.viewOption, styles.viewOptionSelected)}
              to={constructURL(`?view=${VIEW_OPTIONS_TILES}`)}
            >
              <ViewTwoColumnsIcon />
            </Link>
          </li>
        )}
      </ul>
    );
  }
};

export default ViewOptions;
